<template>
  <div class="bg pgbg" :class="$route.query.subjectName">
    <div class="class--con">
      <class-but @forward="forward"/>

    </div>
  </div>
</template>

<script>
import ClassBut from "@/views/conference/ConferenceItem/classBut.vue";
// import menu1 from '@/assets/image/conference/pic_mul.png'

export default {
  name: "firstPage",
  components: {ClassBut},
  data() {
    return {
      // menu1: menu1
    }
  },
  methods: {
    forward() {
      this.$router.push({
        name: 'SmallGameCover',
        query: this.$route.query
      })
    }
  },
}
</script>

<style lang="less" scoped>
@import "reportClass.less";

.pgbg {
  background-image: url("~@/assets/image/conference/bg-1.png");
}
</style>
